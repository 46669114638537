import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Col, Divider, Form, Row, Space, Typography } from 'antd';
import { get, map, omit, times } from 'lodash';
import React, { useState } from 'react';
import {
  INSTRUCTION,
  MODE_OF_CONDUCT,
  QUESTION_ID,
  QUESTION_TYPE,
  ROUTES,
} from '../../../common/constants';
import { handleProtectedNavigation } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import RouterPrompt from '../../../components/RouterPrompt';
import { toastSuccess } from '../../../components/ToastContainer';
import { usePreventNavigation } from '../../../context/PreventNavigationProvider';
import { useSubscription } from '../../../context/SubscriptionProvider';
import useFieldHasError from '../../../hooks/useFieldHasError';
import useRouter from '../../../hooks/useRouter';
import { CREATE_QUIZ, UPDATE_QUIZ } from '../graphql/Mutations';
import { GET_SINGLE_QUIZ_BY_ID } from '../graphql/Queries';
import QuizDetailsForm from './QuizDetailsForm';
import QuizQuestionForm from './QuizQuestionForm';

function QuizForm({ edit }) {
  const [form] = Form.useForm();
  const watchForm = Form.useWatch(['questionData'], form);
  const [isPrompt, setIsPrompt] = useState(false);
  const { params, navigate } = useRouter();
  const { isDirty, setIsDirty, scrollRef } = usePreventNavigation();
  const { refetchQuizList, filter } = useSubscription();
  const hasError = useFieldHasError(form, 'instruction');

  const [createQuiz, { loading: createQuizLoading }] = useMutation(
    CREATE_QUIZ,
    {
      onCompleted: () => {
        navigate(ROUTES.MAIN);
        toastSuccess('Quiz created successfully!');
        refetchQuizList({
          filter,
        });
      },
      onError() {},
    },
  );

  const { loading } = useQuery(GET_SINGLE_QUIZ_BY_ID, {
    skip: !edit,
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        quizId: params?.id,
      },
    },
    onCompleted: (res) => {
      const filesData = {
        ...get(res, 'getQuizById.quiz', {}),
        questionInterval: parseFloat(
          get(res, 'getQuizById.quiz.intervalTime', '0'),
        ),
        questionData: map(
          get(res, 'getQuizById.quiz.questions', []),
          (question) => {
            const { correctAnswer, optionsValues } = question;

            const formattedOptions = map(optionsValues, (option, idx) => ({
              title: option?.title,
              correctAnswer:
                correctAnswer === get(QUESTION_ID, `[${idx}].value`, ''),
            }));
            return {
              ...question,
              optionsValues: times(
                4,
                (idx) =>
                  formattedOptions[idx] || { title: '', correctAnswer: false },
              ),
            };
          },
        ),
      };
      form.setFieldsValue(omit(filesData, 'questions'));
    },
    onError() {},
  });

  const [updateQuiz, { loading: updateQuizLoading }] = useMutation(
    UPDATE_QUIZ,
    {
      onCompleted: () => {
        navigate(ROUTES.MAIN);
        toastSuccess('Quiz updated successfully!');
        refetchQuizList({
          filter,
        });
        setTimeout(() => {
          if (scrollRef?.current) {
            scrollRef?.current?.scrollTo({ top: 0, behavior: 'instant' });
          }
        }, 500);
      },
      onError() {},
    },
  );

  const initialValues = {
    instruction: INSTRUCTION,
    questionData: [
      {
        title: '',
        type: QUESTION_TYPE?.[0]?.value,
        optionsValues: Array.from({ length: 4 }, () => ({
          title: '',
          correctAnswer: false,
        })),
      },
    ],
    quizInitialDelay: 5,
    questionInterval: 10,
    marksPerQuestion: 10,
    modeOfConduct: MODE_OF_CONDUCT?.QUIZ?.key,
  };

  const onFinishFailed = ({ errorFields = [] }) => {
    if (!errorFields?.length) return;
    const errIndex = errorFields[errorFields?.length - 1]?.name?.[1];
    if (errIndex === undefined) return;
    // eslint-disable-next-line no-undef
    const errorElement = document.querySelector(`#question-${errIndex}`);
    if (errorElement) {
      errorElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  const handleFinish = async (values) => {
    try {
      const {
        title,
        description,
        instruction,
        publishStatus,
        quizInitialDelay,
        questionInterval,
        marksPerQuestion,
        modeOfConduct,
      } = values ?? {};

      const quizData = {
        title,
        description,
        instruction,
        publishStatus,
        quizInitialDelay,
        questionInterval,
        marksPerQuestion,
        modeOfConduct,
      };

      const questionData = [
        ...values?.questionData?.map((val) => {
          const questionId = val?.id;
          const correctAnsId = val?.optionsValues?.findIndex(
            (op) => op?.correctAnswer,
          );
          const correctAnswer =
            QUESTION_ID[correctAnsId > -1 ? correctAnsId : 0]?.value;
          const optionsValues = [
            ...val?.optionsValues
              ?.filter((o) => o)
              ?.map((opt, i) => ({
                title: opt?.title,
                id: QUESTION_ID[i]?.value,
              })),
          ];
          return {
            title: val?.title,
            type: val?.type,
            correctAnswer,
            optionsValues,
            ...(questionId && { questionId }),
          };
        }),
      ];

      if (!edit) {
        await createQuiz({ variables: { data: { quizData, questionData } } });
      } else {
        await updateQuiz({
          variables: {
            data: {
              updateQuizData: { ...quizData, quizId: params?.id },
              updateQuestionData: questionData,
            },
          },
        });
      }
    } catch (error) {
      //
    }
  };

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!isDirty, navigate, ROUTES.MAIN));
  };

  return (
    <LoaderComponent spinning={loading} setHeight={100}>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="d-flex justify-between">
              <Typography.Title level={4} className="m-0 head-title">
                Quiz Details
              </Typography.Title>
              <div>
                <Space size="small" wrap>
                  <Button type="default" onClick={handleBack}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => form.submit()}
                    loading={loading || updateQuizLoading || createQuizLoading}
                    disabled={!isDirty}
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </div>
          </>
        }
      >
        <Form
          name="QuizForm"
          className="card-body-wrapper"
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={() => setIsDirty(true)}
          initialValues={initialValues}
        >
          <Row
            gutter={[16, 16]}
            className="quiz-form-wrapper"
            id="quiz-details-section"
          >
            <QuizDetailsForm hasError={hasError} />
            <Col span={24} id="quiz-questions-section">
              <Typography.Title level={4} className="m-0">
                Q&A Section
              </Typography.Title>
              <Divider className="m-0" />
            </Col>
            <QuizQuestionForm
              form={form}
              watchForm={watchForm}
              loading={loading}
            />
          </Row>
        </Form>
        <RouterPrompt
          isPrompt={isPrompt}
          handleOK={() => {
            setIsDirty(false);
            handleProtectedNavigation(true, navigate, -1);
          }}
          handleCancel={() => {
            setIsDirty(true);
            setIsPrompt(false);
          }}
        />
      </Card>
    </LoaderComponent>
  );
}

export default QuizForm;
