import dayjs from 'dayjs';
import { toastError } from '../components/ToastContainer';
import { REGEX, defaultDateFormat } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (dateTime && dayjs && format) {
    return dayjs(dateTime)?.format(format);
  }

  return dateTime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  noSpacesOnly: {
    whitespace: true, // This allows leading/trailing whitespaces but rejects inputs with only whitespace
    message: 'Only spaces are not allowed!',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Enter the valid E-mail!');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
  otp: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.OTP?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid OTP');
      }
      return Promise?.resolve();
    },
  }),
  instruction: () => ({
    validator(_, value) {
      const regex = /^<p>\s*<br>\s*<\/p>$/;
      if (regex?.test(value)) {
        return Promise?.reject(new Error('Instructions required!'));
      }

      const emptySpacesRegex = /<p>\s*<\/p>/;
      if (emptySpacesRegex?.test(value)) {
        return Promise?.reject(new Error('Only spaces are not allowed!'));
      }

      const text = value?.replace(/<[^>]+>/g, '');
      const words = text?.trim()?.split(/\s+/);
      const wordCount = words?.filter((word) => word)?.length;
      if (wordCount > 100) {
        return Promise?.reject(
          new Error(
            `Word limit exceeded (${wordCount})! Please keep your input within 100 words.`,
          ),
        );
      }
      return Promise?.resolve();
    },
  }),
  charLimit: () => ({
    validator({ field }, value) {
      const limits = {
        title: 100,
        description: 250,
        companyName: 50,
      };
      const limit = limits?.[field] ?? 250;
      if (value?.length > limit) {
        return Promise?.reject(
          new Error(
            `Character limit reached! (${value?.length}/${
              limits?.[field] ?? 0
            })`,
          ),
        );
      }
      return Promise?.resolve();
    },
  }),
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    toastError(error?.message);
  }
}

export const getSignedUrl = async () => {
  const response = false;
  if (response) {
    return response?.data;
  }
  return null;
};

export const fetchImage = async () => {
  const response = false;

  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = () => false;

export const beforeUploadImage = (file) => {
  const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png';
  const maxSize = 1; // value in MB
  const maxSizeBytes = maxSize * 1024 * 1024; // convert to bytes
  const isSizeOk = file?.size < maxSizeBytes;

  if (!isJpgOrPng) {
    toastError('You can only upload JPG/PNG file!');
  } else if (!isSizeOk) {
    toastError('Image must smaller than 1MB!');
  }
  return isJpgOrPng && isSizeOk;
};

export const handleProtectedNavigation = (allow, callback, path) =>
  allow ? callback(path) : false;

// Convert object values to number if it's a string representation of a number
export const parseNumbers = (object) => {
  if (!object) return {};
  return Object.entries(object).reduce((acc, [key, value]) => {
    acc[key] =
      typeof value === 'string' && !Number.isNaN(Number(value))
        ? Number(value)
        : value;
    return acc;
  }, {});
};
