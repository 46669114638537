import React from 'react';
import LoaderComponent from '../../components/LoaderComponent';
import { useSubscription } from '../../context/SubscriptionProvider';
import QuizForm from './components/QuizForm';

const AddQuiz = () => {
  const { totalQuiz, isFree, config } = useSubscription();

  return (
    <div>
      <LoaderComponent
        spinning={isFree && totalQuiz && totalQuiz === config?.totalQuiz}
      >
        <QuizForm edit={false} />
      </LoaderComponent>
    </div>
  );
};

export default AddQuiz;
