import { useQuery } from '@apollo/client';
import { Button, Card, Empty, Form, Row, Space, Tabs } from 'antd';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  LIMIT,
  ORDER,
  QUESTION_ID,
  QUIZ_LIST_MODULE,
  ROUTES,
  SORT_ON,
} from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import QuizDetailsForm from './components/QuizDetailsForm';
import QuizQuestionForm from './components/QuizQuestionForm';
import SessionDetails from './components/SessionDetails';
import { GET_SESSIONS, GET_SINGLE_QUIZ_BY_ID } from './graphql/Queries';

function Details() {
  const { params, navigate } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const [paginationConfig, setPaginationConfig] = useState({
    skip: 0,
    showSizeChanger: false,
  });
  const [form] = Form?.useForm();
  const watchForm = Form.useWatch(['questionData'], form);

  const { data: sessionList, loading: getSessionLoading } = useQuery(
    GET_SESSIONS,
    {
      variables: {
        data: {
          quizId: params?.id,
        },
        filter: {
          sortBy: ORDER[0].value,
          sortOn: SORT_ON[0].value,
          skip: paginationConfig?.skip,
          limit: LIMIT,
        },
      },
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const { loading, data } = useQuery(GET_SINGLE_QUIZ_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        quizId: params?.id,
      },
    },
    onCompleted: (res) => {
      form.setFieldsValue({
        ...res?.getQuizById?.quiz,
        questionInterval: parseFloat(res?.getQuizById?.quiz?.intervalTime),
        questionData: res?.getQuizById?.quiz?.questions?.map((items) => ({
          ...items,
          optionsValues: items?.optionsValues?.map((item, idx) => ({
            title: item?.title,
            correctAnswer: items?.correctAnswer === QUESTION_ID[idx]?.value,
          })),
        })),
      });
    },
    onError() {},
  });

  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="d-flex justify-between">
              <div />
              <div>
                <Space size="small" wrap>
                  {searchParams?.get('tab') !== QUIZ_LIST_MODULE.SESSIONS ? (
                    <>
                      <Button
                        type="primary"
                        onClick={() => {
                          navigate(
                            `/edit/${params?.id}${
                              searchParams?.get('tab') === QUIZ_LIST_MODULE.QUIZ
                                ? '#quiz-questions-section'
                                : ''
                            }`,
                          );
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => navigate(ROUTES.MAIN)}
                      >
                        Back
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => navigate(ROUTES.MAIN)}
                    >
                      Back
                    </Button>
                  )}
                </Space>
              </div>
            </div>
          </>
        }
      >
        <Form
          form={form}
          disabled
          layout="vertical"
          className="details-form card-body-wrapper"
        >
          <Tabs
            activeKey={searchParams.get('tab') || QUIZ_LIST_MODULE.DETAILS}
            onChange={(key) => setSearchParams({ tab: key })}
            defaultActiveKey={QUIZ_LIST_MODULE.DETAILS}
            className="tabs"
            items={[
              {
                key: QUIZ_LIST_MODULE.DETAILS,
                label: QUIZ_LIST_MODULE.DETAILS,
                children: loading ? (
                  <LoaderComponent setHeight="50" />
                ) : (
                  <Row gutter={[16, 16]}>
                    <QuizDetailsForm showInstruction={false} />
                  </Row>
                ),
              },
              {
                key: QUIZ_LIST_MODULE.QUIZ,
                label: QUIZ_LIST_MODULE.QUIZ,
                children:
                  data?.getQuizById?.quiz?.questions?.length > 0 ? (
                    <QuizQuestionForm
                      addNewQuiz={false}
                      isFormDisabled
                      watchForm={watchForm}
                    />
                  ) : (
                    <Empty description="No quiz" />
                  ),
              },
              {
                key: QUIZ_LIST_MODULE.SESSIONS,
                label: QUIZ_LIST_MODULE.SESSIONS,
                children: (
                  <SessionDetails
                    sessionList={sessionList}
                    setPaginationConfig={setPaginationConfig}
                    getSessionLoading={getSessionLoading}
                    paginationConfig={paginationConfig}
                  />
                ),
              },
            ]}
          />
        </Form>
      </Card>
    </>
  );
}

export default Details;
