import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { map } from 'lodash';
import React from 'react';
import {
  MODE_OF_CONDUCT,
  QUESTION_ATTEMPT_TIME,
  QUIZ_START_TIME,
  STATUS_LIST,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import QuillEditor from '../../../components/Editor/QuillEditor';
import { useSubscription } from '../../../context/SubscriptionProvider';
import useRouter from '../../../hooks/useRouter';

const { noSpacesOnly, instruction, charLimit } = formValidatorRules;

const QuizDetailsForm = ({ showInstruction = true, hasError = false }) => {
  const { isFree } = useSubscription();
  const { location } = useRouter();
  const isDetailsTab = location?.pathname?.includes('details');

  return (
    <>
      <Col span={showInstruction ? 12 : 24}>
        <Row gutter={16}>
          <Col span={24}>
            {' '}
            <Form.Item
              label="Title"
              name="title"
              validateFirst
              rules={[
                { required: true, message: 'Title required!' },
                charLimit,
                noSpacesOnly,
              ]}
            >
              <Input placeholder="Enter Quiz Title" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              validateFirst
              rules={[
                { required: true, message: 'Description required!' },
                charLimit,
                noSpacesOnly,
              ]}
            >
              <Input placeholder="Enter Description" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="custom-required">
              Quiz Start Time
              <span className="asterisk">*</span>
              <span className="color-red">(sec)</span>
            </div>
            <Form.Item
              name="quizInitialDelay"
              rules={[
                {
                  required: true,
                  message: 'Quiz start time required!',
                },
              ]}
            >
              <Select
                options={QUIZ_START_TIME}
                disabled={isFree || isDetailsTab}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="custom-required">
              Question Attempt Time
              <span className="asterisk">*</span>
              <span className="color-red">(sec)</span>
            </div>
            <Form.Item
              name="questionInterval"
              rules={[
                {
                  required: true,
                  message: 'Question attempt time required!',
                },
              ]}
            >
              <Select
                options={QUESTION_ATTEMPT_TIME}
                disabled={isFree || isDetailsTab}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Marks Per Question"
              name="marksPerQuestion"
              rules={[
                { required: true, message: 'Marks per question required!' },
              ]}
            >
              <InputNumber
                placeholder="Enter Marks Per Question"
                min={1}
                disabled={isFree || isDetailsTab}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="publishStatus"
              label="Publish Status"
              rules={[{ required: true, message: 'Status required!' }]}
            >
              <Select placeholder="Select Status">
                {map(STATUS_LIST, (list) => (
                  <Select.Option key={list?.value} value={list?.value}>
                    {list?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="modeOfConduct" label="Mode">
              <Select
                placeholder="Select mode"
                disabled={isFree || isDetailsTab}
              >
                {map(MODE_OF_CONDUCT, (mode) => (
                  <Select.Option key={mode?.key} value={mode?.key}>
                    {mode?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      {showInstruction && (
        <Col span={12} className="instructions">
          <Form.Item
            label="Instruction"
            name="instruction"
            rules={[{ required: true }, instruction]}
          >
            <QuillEditor hasError={hasError} />
          </Form.Item>
        </Col>
      )}
    </>
  );
};
export default QuizDetailsForm;
