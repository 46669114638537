import { Modal, Pagination, Spin } from 'antd';
import React, { useState } from 'react';
import { LIMIT } from '../../../common/constants';
import TableComponent from '../../../components/CommonTable';

export default function SessionUserList({
  sessionId,
  isModalOpen,
  setSessionId,
  setIsModalOpen,
  getParticipants,
  participantsData,
  participantsLoading,
}) {
  const [page, setPage] = useState(1);

  const ParticipantsColums = [
    {
      title: 'Name',
      dataIndex: 'userName',
      key: ' userName',
      render: (_, value) => value?.userName || '-',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (_, value) =>
        Number(value.score) > 0
          ? Math?.round(Number(value?.score) * 100) / 100
          : 0,
    },
    {
      title: 'Total Correct Answers',
      dataIndex: 'totalCorrectAnswers',
      key: 'totalCorrectAnswers',
      width: 140,
      render: (_, value) => value?.totalCorrectAnswers,
    },
  ];

  const handleParticipantsCancel = () => {
    setIsModalOpen(false);
    setSessionId('');
    setPage(1);
  };

  return (
    <Modal
      title="Session Result"
      open={isModalOpen}
      onCancel={handleParticipantsCancel}
      footer={false}
      centered
    >
      <div className="participants-container">
        {participantsLoading ? (
          <div className="loader-container">
            <Spin size="large" />
          </div>
        ) : (
          <>
            <TableComponent
              rowKey="userName"
              rowClassName="pointer"
              scroll={{ y: '60vh' }}
              columns={ParticipantsColums}
              data={participantsData?.getParticipants?.participants}
              pagination={false}
            />
            <Pagination
              total={participantsData?.getParticipants?.count || 0}
              current={page}
              pageSize={LIMIT}
              onChange={(newPage) => {
                setPage(newPage);
                getParticipants({
                  variables: {
                    data: {
                      sessionId,
                    },
                    filter: {
                      skip: (newPage - 1) * LIMIT,
                      limit: LIMIT,
                    },
                  },
                });
              }}
            />
          </>
        )}
      </div>
    </Modal>
  );
}
