import { Form } from 'antd';
import { useEffect, useState } from 'react';

export default function useFieldHasError(form, field = 'instruction') {
  const watchInstruction = Form.useWatch([`${field}`], form);
  const [hasError, setHasError] = useState(false);

  const validateField = async () => {
    try {
      await form.validateFields([`${field}`]);
      setHasError(false);
    } catch (error) {
      setHasError(true);
    }
  };

  useEffect(() => {
    validateField();
  }, [watchInstruction]);

  return hasError;
}
