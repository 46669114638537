import { useQuery } from '@apollo/client';
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AppContext } from '../AppContext';
import { LIMIT, ORDER, PLANS, SORT_ON } from '../common/constants';
import { parseNumbers } from '../common/utils';
import { GET_SUBSCRIPTION_PLANS } from '../modules/plans/graphql/Queries';
import { GET_QUIZZES } from '../modules/quiz/graphql/Queries';
import {
  GET_USER,
  GET_USER_SUBSCRIPTION,
} from '../modules/subscription/graphql/Queries';

const { FREE } = PLANS;
const SubscriptionContext = createContext(null);

const SubscriptionProvider = ({ children }) => {
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [refetchData, setRefetchData] = useState(null);
  const [btnLoadingOff, setBtnLoadingOff] = useState(null);
  const [quizLoader, setQuizLoader] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [featureText, setFeatureText] = useState({
    title: '',
    subText: '',
  });

  const [filter, setFilter] = useState({
    skip: 0,
    limit: LIMIT,
    search: '',
    sortBy: ORDER[0]?.value,
    sortOn: SORT_ON[0]?.value,
  });

  const {
    state: { authToken },
  } = useContext(AppContext);

  const { data: totalQuizzes, refetch: refetchTotalQuizzes } = useQuery(
    GET_QUIZZES,
    {
      skip: !authToken,
      fetchPolicy: 'no-cache',
      variables: {
        filter: {
          limit: null,
        },
      },
    },
  );

  const {
    data: quizList,
    loading: loadingQuizList,
    refetch: $refetch,
    fetchMore,
  } = useQuery(GET_QUIZZES, {
    skip: !authToken,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter,
    },
    onCompleted: () => {
      setQuizLoader(false);
      refetchTotalQuizzes();
    },
    onError: () => {
      setQuizLoader(false);
    },
  });

  const refetchQuizList = useCallback(
    (arg) => {
      $refetch({
        ...arg,
      });
    },
    [$refetch],
  );

  const { data: plansData } = useQuery(GET_SUBSCRIPTION_PLANS, {
    skip: !authToken,
    fetchPolicy: 'network-only',
    onError() {},
  });

  const { data: getUser } = useQuery(GET_USER, {
    skip: !authToken,
    fetchPolicy: 'network-only',
    onError() {},
  });

  const { data: getUserSubscription, refetch: subscriptionRefetch } = useQuery(
    GET_USER_SUBSCRIPTION,
    {
      variables: {
        data: {
          userId: getUser?.getUser?.id,
        },
      },
      skip: !getUser?.getUser?.id,
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const currentPlan = useMemo(
    () => getUserSubscription?.getUserSubscription?.subscriptionPlanName,
    [getUserSubscription],
  );

  const isFree = useMemo(() => (!currentPlan ? true : currentPlan === FREE), [
    currentPlan,
  ]);

  const config = useMemo(() => {
    const { subscriptionFeatures } =
      getUserSubscription?.getUserSubscription?.userSubscription || {};
    if (!subscriptionFeatures) return {};

    const jsonParse = JSON.parse(subscriptionFeatures);
    const features = parseNumbers(jsonParse);

    return {
      totalSession: features?.allowedSession,
      totalQuiz: features?.allowedQuiz,
    };
  }, [getUserSubscription]);

  const refetchSubscription = useCallback(() => {
    subscriptionRefetch({
      data: {
        userId: getUser?.getUser?.id,
      },
    });
  }, [subscriptionRefetch, getUser?.getUser?.id]);

  const totalQuiz = useMemo(() => totalQuizzes?.getQuizzes?.quizzes?.length, [
    totalQuizzes?.getQuizzes?.quizzes?.length,
  ]);

  const value = useMemo(
    () => ({
      setOpenUpgradeModal,
      openUpgradeModal,
      openLoader,
      setOpenLoader,
      featureText,
      setFeatureText,
      plansData,
      currentPlan,
      getUserSubscription,
      refetchSubscription,
      getUser,
      isFree,
      config,
      totalQuiz,
      // quiz list api values
      quizList,
      refetchQuizList,
      fetchMore,
      setFilter,
      filter,
      refetchData,
      setRefetchData,
      btnLoadingOff,
      setBtnLoadingOff,
      loadingQuizList,
      quizLoader,
      setQuizLoader,
      searchValue,
      setSearchValue,
    }),
    [
      setOpenUpgradeModal,
      openUpgradeModal,
      openLoader,
      setOpenLoader,
      featureText,
      setFeatureText,
      plansData,
      currentPlan,
      getUserSubscription,
      refetchSubscription,
      getUser,
      isFree,
      config,
      totalQuiz,
      // quiz list api values
      quizList,
      refetchQuizList,
      fetchMore,
      setFilter,
      filter,
      refetchData,
      setRefetchData,
      btnLoadingOff,
      setBtnLoadingOff,
      loadingQuizList,
      quizLoader,
      setQuizLoader,
      searchValue,
      setSearchValue,
    ],
  );

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => {
  const ctx = useContext(SubscriptionContext);
  if (!ctx)
    throw new Error('useSubscription must be used inside SubscriptionProvider');

  return ctx;
};

export default SubscriptionProvider;
