import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ROUTES } from '../common/constants';
import useRouter from '../hooks/useRouter';

const PreventNavigationContext = createContext();

export default function PreventNavigationProvider({ children }) {
  const [isDirty, setIsDirty] = useState(false);
  const [isLogoClicked, setIsLogoClicked] = useState(false);
  const scrollRef = useRef(null);
  const lastScrollPosition = useRef(0);
  const { location, navigate } = useRouter();
  const {
    confirm,
    addEventListener,
    removeEventListener,
    // eslint-disable-next-line no-undef
  } = window;

  const updateIsDirty = (val) => {
    setIsDirty(val);
  };

  const changesLostWarning = useCallback(() => {
    if (!isDirty) return;
    // eslint-disable-next-line no-alert
    const leavePage = confirm(
      'If you leave this page all your changes would be lost!',
    );
    if (leavePage) {
      updateIsDirty(false);
    } else {
      navigate(location?.pathname);
    }
    return leavePage;
  }, [isDirty, location?.pathname]);

  // eslint-disable-next-line no-undef
  window.onpopstate = changesLostWarning;

  useEffect(() => {
    updateIsDirty(false);

    if (!scrollRef?.current) return;
    if (location?.pathname !== ROUTES?.MAIN || isLogoClicked) {
      scrollRef?.current?.scrollTo({ top: 0, behavior: 'instant' });
      if (isLogoClicked) {
        setIsLogoClicked(false);
      }
    } else if (lastScrollPosition?.current) {
      scrollRef?.current?.scrollTo({
        top: lastScrollPosition?.current,
        behavior: 'instant',
      });
    }

    const handleScroll = () => {
      if (location?.pathname === ROUTES?.MAIN && scrollRef?.current) {
        lastScrollPosition.current = scrollRef?.current?.scrollTop;
      }
    };

    scrollRef?.current?.addEventListener('scroll', handleScroll);
    return () =>
      scrollRef?.current?.removeEventListener('scroll', handleScroll);
  }, [location?.pathname]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e?.preventDefault();
        // eslint-disable-next-line no-param-reassign
        e.returnValue = '';
      }
    };
    addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const value = useMemo(
    () => ({
      isDirty,
      setIsDirty,
      changesLostWarning,
      isLogoClicked,
      setIsLogoClicked,
      scrollRef,
    }),
    [
      isDirty,
      setIsDirty,
      changesLostWarning,
      isLogoClicked,
      setIsLogoClicked,
      scrollRef,
    ],
  );

  return (
    <PreventNavigationContext.Provider value={value}>
      {children}
    </PreventNavigationContext.Provider>
  );
}

export const usePreventNavigation = () => {
  const ctx = useContext(PreventNavigationContext);
  if (!ctx)
    throw new Error(
      'usePreventNavigation must be used inside PreventNavigationProvider',
    );
  return ctx;
};
