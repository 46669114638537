import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useSubscription } from '../../context/SubscriptionProvider';
import EditorToolbar, { formats, modules } from './QuillToolbar';

const QuillEditor = ({ value, hasError = false, ...rest }) => {
  const { isFree } = useSubscription();
  // eslint-disable-next-line no-undef
  const url = window.location.href;
  const isAddOrEdit = url.includes('add') || url.includes('edit');

  return (
    <div
      className={`${
        // eslint-disable-next-line no-nested-ternary
        hasError
          ? 'text-editor editor-error'
          : isFree && !isAddOrEdit
          ? 'text-editor text-editor-disabled'
          : 'text-editor-events'
      }
      `}
    >
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        placeholder="Write something awesome..."
        modules={modules}
        formats={formats}
        className="editor"
        readOnly={isFree && !isAddOrEdit}
        {...rest}
      />
    </div>
  );
};

export default QuillEditor;
