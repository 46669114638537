import {
  CheckOutlined,
  CopyOutlined,
  CrownFilled,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  FloatButton,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { debounce, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FETCH_LIMIT,
  LIMIT,
  ORDER,
  ROUTES,
  STATUS_LIST,
} from '../../common/constants';
import EmptyStatePage from '../../components/EmptyStatePage';
import LoaderComponent from '../../components/LoaderComponent';
import { useSubscription } from '../../context/SubscriptionProvider';
import useRouter from '../../hooks/useRouter';
import withFeatureRestriction from '../plans/components/withFeatureRestriction';
import { CREATE_SESSION, DELETE_QUIZ } from './graphql/Mutations';

const { Meta } = Card;
const { Text } = Typography;

const AddQuizButton = ({ onClick }) => {
  const { isFree, config, totalQuiz, setFeatureText } = useSubscription();
  const { navigate } = useRouter();
  const quizLimitExceeded = totalQuiz >= Number(config?.totalQuiz);
  const handleOnClick = () => {
    if (isFree && quizLimitExceeded) {
      setFeatureText((prev) => ({
        ...prev,
        title: 'Oops! You’ve Hit the Limit',
        subText:
          'You’ve created 3 quizzes already, which is the limit for the free plan. Want to keep going? Upgrade your plan and keep the creativity flowing!',
      }));
      onClick();
      return;
    }

    navigate(ROUTES.ADD_QUIZ);
  };

  return (
    <Button type="primary" onClick={handleOnClick}>
      {quizLimitExceeded && isFree && <CrownFilled className="crown-icon" />}
      Add Quiz
    </Button>
  );
};

const CheckRestrictionOnAddQuiz = withFeatureRestriction(AddQuizButton);

function NewQuizz() {
  const {
    config,
    isFree,
    setOpenUpgradeModal,
    setFeatureText,
    setFilter,
    filter,
    quizList,
    fetchMore,
    loadingQuizList,
    quizLoader,
    setQuizLoader,
    searchValue,
    setSearchValue,
    refetchQuizList,
  } = useSubscription();
  const { navigate } = useRouter();
  const [hasMore, setHasMore] = useState(false);

  const [createSession] = useMutation(CREATE_SESSION, { onError() {} });

  const [deleteQuiz] = useMutation(DELETE_QUIZ, {
    update: (cache, _, { variables }) => {
      try {
        cache?.modify({
          fields: {
            getQuizzes(existingQuizData, { readField }) {
              return {
                ...existingQuizData,
                quizzes: existingQuizData?.quizzes?.filter(
                  (quiz) => readField('id', quiz) !== variables?.data?.quizId,
                ),
                count: existingQuizData?.count - 1,
              };
            },
          },
        });
      } catch (error) {
        //
      }
    },
    onError() {},
    onCompleted: () => {
      if (isFree) {
        refetchQuizList({
          filter,
        });
      }
    },
  });

  const handleHostLink = async (quiz, action = '') => {
    if (isFree && quiz?.totalNoSession >= Number(config?.totalSession)) {
      setFeatureText((prev) => ({
        ...prev,
        title: 'Session Limit Exceeded',
        subText:
          'Free plan users can host up to 5 sessions per quiz. Upgrade now to enjoy unlimited sessions and enhanced hosting capabilities!',
      }));
      setOpenUpgradeModal(true);
      return;
    }
    if (quiz?.publishStatus === STATUS_LIST?.NOT_PUBLISHED?.value) return;
    const { data: session, errors } = await createSession({
      variables: {
        data: {
          quizId: quiz?.id,
        },
      },
      update(cache, { data: { createSession: createdSession } }) {
        try {
          if (!createdSession?.sessions) return;
          cache?.modify({
            id: cache?.identify({ __typename: 'Quiz', id: quiz?.id }),
            fields: {
              totalNoSession(existingTotal = 0) {
                return existingTotal + 1;
              },
            },
          });
        } catch (error) {
          //
        }
      },
    });
    if (!errors && session?.createSession?.sessions?.slug) {
      const sessionUrl = `${process.env.REACT_APP_TV_APP_URL}${session?.createSession?.sessions?.slug}`;
      if (action === 'copy') {
        return Promise.resolve(sessionUrl);
      }
      // eslint-disable-next-line no-undef
      window.open(sessionUrl, '_blank');
    }
  };

  const loadMore = () => {
    setQuizLoader(true);
    fetchMore({
      variables: {
        filter: {
          ...filter,
          skip: quizList?.getQuizzes?.quizzes?.length,
          limit: FETCH_LIMIT,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (fetchMoreResult?.getQuizzes?.quizzes?.length === 0) {
          setHasMore(false);
          return previousResult;
        }
        const updatedQuizList = {
          ...previousResult,
          getQuizzes: {
            ...previousResult?.getQuizzes,
            quizzes: [
              ...previousResult?.getQuizzes?.quizzes,
              ...fetchMoreResult?.getQuizzes?.quizzes,
            ],
          },
        };
        return updatedQuizList;
      },
    }).then(() => {
      setQuizLoader(false);
    });
  };

  const debouncedSearch = useCallback(
    debounce((text) => {
      setFilter((prev) => ({
        ...prev,
        search: text,
        limit: text ? quizList?.getQuizzes?.count || null : LIMIT,
      }));
    }, 500),
    [quizList?.getQuizzes?.count],
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    setHasMore(
      quizList?.getQuizzes?.quizzes?.length < quizList?.getQuizzes?.count,
    );
  }, [quizList]);

  return (
    <>
      <Card
        className="ant-body-scroll"
        title={
          <>
            <div className="d-flex justify-between">
              <div>
                <Space size="small" wrap>
                  {!isFree && (
                    <>
                      <Text type="secondary">Sort By</Text>
                      <Select
                        className="order-by-select-input"
                        placeholder="Select list order"
                        defaultValue={filter?.sortBy}
                        options={ORDER}
                        onChange={(value) => {
                          setQuizLoader(true);
                          setFilter((prev) => ({
                            ...prev,
                            sortBy: value,
                          }));
                        }}
                      />
                    </>
                  )}
                  <Input
                    allowClear
                    value={searchValue}
                    suffix={<SearchOutlined />}
                    placeholder="Search"
                    className="search-component"
                    onChange={({ target: { value: text } }) => {
                      setSearchValue(text);
                    }}
                  />
                </Space>
              </div>
              <CheckRestrictionOnAddQuiz />
            </div>
          </>
        }
      >
        <FloatButton.BackTop
          // eslint-disable-next-line no-undef
          target={() => document.querySelector('.content-wrapper')}
          visibilityHeight={100}
        />
        <div className="card-body-wrapper">
          {/* eslint-disable-next-line no-nested-ternary */}
          {!quizList?.getQuizzes?.quizzes?.length ? (
            loadingQuizList ? (
              <LoaderComponent setHeight="50" />
            ) : (
              <EmptyStatePage />
            )
          ) : (
            <Row gutter={[16, 16]}>
              {map(quizList?.getQuizzes?.quizzes, (data) => {
                const unpublished =
                  data?.publishStatus === STATUS_LIST?.NOT_PUBLISHED?.value;
                return (
                  <Col
                    className="actions"
                    key={data?.id}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                  >
                    <Card
                      hoverable
                      className="quiz-card full-width"
                      actions={[
                        isFree &&
                        data?.totalNoSession >= Number(config?.totalSession) ? (
                          <Button
                            type="text"
                            onClick={() => handleHostLink(data)}
                          >
                            <CrownFilled className="crown-icon " />
                            <span className="link-text link-enabled">Link</span>
                            <span key="copying">
                              <CopyOutlined
                                key="copy-icon"
                                className="color-red"
                              />
                            </span>
                          </Button>
                        ) : (
                          <Typography.Paragraph
                            onClick={() => handleHostLink(data)}
                            key="copy-link"
                            className="copy-text"
                            disabled={unpublished}
                            copyable={
                              unpublished
                                ? false
                                : {
                                    icon: [
                                      <span key="copying">
                                        <CopyOutlined key="copy-icon" />
                                      </span>,
                                      <span key="copied">
                                        <CheckOutlined key="copied-icon" />
                                      </span>,
                                    ],
                                    tooltips: 'Copy quiz link',
                                    text: () => handleHostLink(data, 'copy'),
                                  }
                            }
                          >
                            <Tooltip
                              title={
                                unpublished
                                  ? 'Publish quiz to generate link'
                                  : 'Redirect to quiz screen'
                              }
                            >
                              <p
                                className={`link-text ${
                                  unpublished ? 'link-disabled' : 'link-enabled'
                                }`}
                              >
                                Link
                              </p>
                              {unpublished && (
                                <CopyOutlined className="copy-disabled" />
                              )}
                            </Tooltip>
                          </Typography.Paragraph>
                        ),
                        <Tooltip key="detail" title="View" className="link">
                          <Button
                            type="text"
                            onClick={() => {
                              navigate(`/details/${data?.id}`);
                            }}
                          >
                            <EyeOutlined />
                          </Button>
                        </Tooltip>,
                        <Tooltip key="edit" title="Edit" className="link">
                          <Button
                            type="text"
                            onClick={() => {
                              navigate(`/edit/${data?.id}`);
                            }}
                          >
                            <EditOutlined />
                          </Button>
                        </Tooltip>,
                        <Tooltip key="delete" title="Delete" className="link">
                          <Popconfirm
                            title="Delete Quiz"
                            description="Are you sure you want to delete this quiz?"
                            okText="Confirm"
                            onConfirm={() =>
                              deleteQuiz({
                                variables: { data: { quizId: data?.id } },
                              })
                            }
                          >
                            <Button type="text">
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                        </Tooltip>,
                      ]}
                    >
                      <Meta
                        title={
                          <div className="card-title d-flex justify-between">
                            <p className="m-0 quiz-title">{data?.title}</p>
                            <Tag
                              className="d-flex align-center"
                              color={unpublished ? 'error' : 'success'}
                            >
                              {STATUS_LIST?.[data?.publishStatus]?.name}
                            </Tag>
                          </div>
                        }
                        description={
                          <Typography.Paragraph ellipsis={{ rows: 2 }}>
                            {data?.description}
                          </Typography.Paragraph>
                        }
                      />
                      <div className="d-flex justify-around text-center">
                        <div>
                          <p className="mb-0">Questions</p>
                          <p className="mb-0">{data?.totalNoQuestion}</p>
                        </div>
                        <div>
                          <p className="mb-0">Sessions</p>
                          <p className="mb-0">{data?.totalNoSession}</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
        {hasMore && !isFree && !filter?.search && (
          <div className="load-more">
            {quizLoader ? (
              <LoaderComponent size="medium" setHeight="5" />
            ) : (
              <Button onClick={loadMore}>Load More</Button>
            )}
          </div>
        )}
      </Card>
    </>
  );
}

export default NewQuizz;
