import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App, ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Workbox } from 'workbox-window';
import { AppContextProvider } from './AppContext';
import Routes from './RoutesWrapper';
import client from './apollo';
import { PRIMARY_COLOR } from './common/constants';
import AppContextHolder from './components/AppContextHolder';
import ConnectionMode from './components/ConnectionMode';
import CookieConsent from './components/CookieConsent';
import SubscriptionProvider from './context/SubscriptionProvider';
import './index.css';
import './styles/main.less';
import themeJson from './styles/style.json';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

// use this variable from envs so that we can active connection mode feature in app.
const connectionMode = process.env.REACT_APP_CONNECTION_MODE_ENABLE;

// use this variable from envs so that we can active cookie consent mode feature in app.
const cookieMode = process.env.REACT_APP_COOKIE_CONSENT_ENABLE;

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(document?.getElementById('root'));

const VERSION = require('../package.json').version;

// eslint-disable-next-line no-console
console?.log('APP_VERSION', VERSION);

// eslint-disable-next-line no-undef
if ('serviceWorker' in navigator) {
  const wb = new Workbox('/sw.js');

  wb.register();
}

if (process.env?.REACT_APP_ENV !== 'local') {
  Sentry.init({
    dsn: process.env?.REACT_APP_SENTRY_DSN,
    environment: process.env?.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });
}

const ConfigProviderWrapper = ({ children }) => (
  <ConfigProvider
    theme={{
      cssVar: true,
      token: {
        ...themeJson.token,
        colorPrimary: PRIMARY_COLOR,
      },
      components: themeJson.components,
    }}
  >
    {children}
  </ConfigProvider>
);

root?.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <App>
        {/* Adding toast-container to render toast messages [ant v5.10.1] */}
        <AppContextHolder />
        <SubscriptionProvider>
          <ConfigProviderWrapper>
            {/* Adding connection mode to notify user when they are offline/online */}
            {connectionMode === 'true' && <ConnectionMode />}
            {/* Adding cookie consent component to inform users about cookies we use */}
            {cookieMode === 'true' && <CookieConsent />}
            <Routes />
          </ConfigProviderWrapper>
        </SubscriptionProvider>
      </App>
    </AppContextProvider>
  </ApolloProvider>,
);
