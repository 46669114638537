import React, { useContext, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import { useSubscription } from './context/SubscriptionProvider';

const PrivateRoute = () => {
  const { getToken, authLoading } = useContext(AppContext);
  const { pathname } = useLocation();
  const idToken = getToken();
  const { isFree, totalQuiz, config } = useSubscription();

  const restrictAddQuiz = useMemo(
    () =>
      isFree && pathname === ROUTES.ADD_QUIZ && totalQuiz >= config?.totalQuiz,
    [pathname, totalQuiz, isFree],
  );

  if (authLoading) {
    return <LoaderComponent />;
  }

  if (!idToken) {
    return <Navigate to={ROUTES?.LOGIN} />;
  }

  if (restrictAddQuiz) {
    return <Navigate to={ROUTES?.MAIN} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
