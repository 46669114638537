import { Col, Row } from 'antd';
import Ribbon from 'antd/es/badge/Ribbon';
import { capitalize, map } from 'lodash';
import React from 'react';
import { PLANS } from '../../common/constants';
import { useSubscription } from '../../context/SubscriptionProvider';
import PlanCard from './components/PlanCard';
import './plans.less';

const ActivePlan = ({ children, isActive }) =>
  isActive ? (
    <Ribbon
      text="Active Plan"
      color="green"
      rootClassName="active-plan-card-ribbon"
    >
      {children}
    </Ribbon>
  ) : (
    children
  );

function PlanList() {
  const { getUserSubscription, plansData } = useSubscription();
  const { subscriptionPlans = [] } = plansData?.getSubscriptionPlans ?? {};
  const planOrder = Object?.values(PLANS);

  return (
    <div className="plans-module">
      <div className="plan-list-wrapper">
        <div className="plan-card-container d-flex gap-24 justify-center">
          <Row gutter={[24, 24]} justify="center">
            {subscriptionPlans?.length > 0 &&
              map(
                subscriptionPlans
                  ?.slice()
                  ?.sort(
                    (a, b) =>
                      planOrder?.indexOf(a?.planName) -
                      planOrder?.indexOf(b?.planName),
                  ),
                (plan) => (
                  <Col sm={12} md={8} lg={8} key={plan?.id}>
                    <ActivePlan
                      isActive={
                        plan?.id ===
                        getUserSubscription?.getUserSubscription
                          ?.userSubscription?.subscriptionPlanId
                      }
                    >
                      <PlanCard
                        title={capitalize(plan?.planName)}
                        amount={plan?.amount}
                        planName={plan?.planName}
                        info={plan?.description}
                        plan={plan}
                      />
                    </ActivePlan>
                  </Col>
                ),
              )}
          </Row>
        </div>
      </div>
    </div>
  );
}

export default PlanList;
