import { CrownFilled, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Form,
  Input,
  message,
  Row,
  Space,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import {
  beforeUpload,
  fileUpload,
  formValidatorRules,
  handleProtectedNavigation,
} from '../../common/utils';
import QuillEditor from '../../components/Editor/QuillEditor';
import LoaderComponent from '../../components/LoaderComponent';
import RouterPrompt from '../../components/RouterPrompt';
import { usePreventNavigation } from '../../context/PreventNavigationProvider';
import { useSubscription } from '../../context/SubscriptionProvider';
import useFieldHasError from '../../hooks/useFieldHasError';
import useRouter from '../../hooks/useRouter';
import DeleteAccount from './components/DeleteAccount';
import { UPDATE_CONFIG } from './graphql/Mutations';
import {
  GET_COMPANYLOGO_UPLOAD_SIGNEDURL,
  GET_CONFIG,
} from './graphql/Queries';

const {
  required,
  noSpacesOnly,
  name,
  instruction,
  charLimit,
} = formValidatorRules;

function OnBoarding() {
  const { navigate } = useRouter();
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form?.useForm();
  const [isPrompt, setIsPrompt] = useState(false);
  const [curImg, setCurImg] = useState('');
  const [config, setConfig] = useState({});
  const { isDirty, setIsDirty } = usePreventNavigation();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { isFree } = useSubscription();
  const { getCurrentUser, getToken, dispatch } = useContext(AppContext);
  const hasError = useFieldHasError(form, 'instructions');
  const currentUser = getCurrentUser();
  const accessToken = getToken();
  const refreshToken = currentUser?.refreshToken;

  const [signurlImage] = useLazyQuery(GET_COMPANYLOGO_UPLOAD_SIGNEDURL, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  const setUser = (configValue) => {
    dispatch({
      type: 'SET_CURRENT_USER',
      data: {
        accessToken,
        ...currentUser,
        ...configValue,
        isConfigSet: true,
        refreshToken,
      },
    });
  };

  const { loading } = useQuery(GET_CONFIG, {
    onCompleted: ({ getConfig }) => {
      const instructions = getConfig?.config?.instructions;
      const htmlRegex = /^<\w+>.*<\/\w+>$/s;
      const isHtml = htmlRegex.test(instructions.trim());
      const newInstructions = isHtml ? instructions : `<p>${instructions}</p>`;

      setConfig({ ...getConfig?.config, instructions: newInstructions });
      setCurImg(getConfig?.config?.companyLogo);
      form.setFieldsValue({
        ...getConfig?.config,
        primaryColor: getConfig?.config?.primaryColor || '#000000',
        instructions: newInstructions,
      });
    },
    onError() {},
    fetchPolicy: 'network-only',
  });

  const uploadButton = (
    <div>
      {btnLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-8">Upload</div>
    </div>
  );

  const handleBack = () => {
    setIsPrompt(!handleProtectedNavigation(!isDirty, navigate, -1));
  };

  const handleOk = () => {
    setIsDirty(false);
    handleProtectedNavigation(true, navigate, -1);
  };

  const handleClose = () => {
    setIsDirty(true);
    setIsPrompt(false);
  };

  const imgUpload = async (file) => {
    setUploading(true);
    const { data } = await signurlImage({
      variables: {
        data: {
          fileName: file?.name,
        },
      },
    });

    const { key, signedUrl } = data?.getCompanyLogoUploadSignedUrl || {};
    if (key) {
      await fileUpload(signedUrl, file);
      setUploading(false);
      return key;
    }
    return '';
  };

  const onFinish = async (values) => {
    setBtnLoading(true);
    const selectedFile = fileList?.[0]?.originFileObj;
    const color = values?.primaryColor;
    try {
      const configValue = {
        ...values,
        primaryColor: typeof color === 'string' ? color : color?.toHexString(),
        companyLogo: selectedFile ? await imgUpload(selectedFile) : '',
        configId: config?.id,
      };

      if (currentUser?.isVerified && currentUser?.isConfigSet) {
        await updateConfig({
          variables: {
            data: configValue,
          },
          onCompleted: (data) => {
            setUser(data?.updateConfig?.configs);
            navigate(ROUTES.MAIN);
            setBtnLoading(false);
          },
        });
      }
    } catch (error) {
      //
    } finally {
      setBtnLoading(false);
    }
  };

  const onFileChange = (info) => {
    if (info?.file?.size / 1024 / 1024 > 5) {
      message.error('Image size must be less than 5MB!');
      return;
    }
    setFileList(info?.fileList);
    setCurImg(URL.createObjectURL(info?.fileList?.[0]?.originFileObj));
  };

  const SaveTooltip = ({ children }) =>
    isFree ? (
      <Tooltip
        placement="topRight"
        title="This functionality is not available for free users. Upgrade to access this feature."
      >
        {children}
      </Tooltip>
    ) : (
      children
    );

  return (
    <>
      <LoaderComponent spinning={loading}>
        <Form
          form={form}
          className="sticky-action-form"
          onValuesChange={() => setIsDirty(true)}
          layout="vertical"
          onFinish={onFinish}
          disabled={isFree}
        >
          <Card
            className="ant-body-scroll"
            title={
              <>
                <div className="d-flex justify-between">
                  <Typography.Title level={4} className="m-0 head-title">
                    My Account
                  </Typography.Title>
                  <div>
                    <Space>
                      <DeleteAccount />
                      {currentUser?.isConfigSet && (
                        <Button
                          disabled={btnLoading}
                          onClick={() =>
                            isFree ? navigate(ROUTES.MAIN) : handleBack()
                          }
                        >
                          Cancel
                        </Button>
                      )}
                      <SaveTooltip>
                        <Button
                          icon={
                            isFree && <CrownFilled className="crown-icon" />
                          }
                          type="primary"
                          loading={btnLoading || uploading}
                          onClick={() => {
                            if (!isFree) {
                              form.submit();
                            }
                          }}
                          disabled={loading || isFree || !isDirty}
                        >
                          Save
                        </Button>
                      </SaveTooltip>
                    </Space>
                  </div>
                </div>
              </>
            }
          >
            <div className="card-body-wrapper">
              <Row gutter={[16, 16]}>
                <Col xs={24} lg={24} xl={24}>
                  <Form.Item name="companyLogo" label="Company Logo">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      accept="image/png, image/jpeg"
                      onChange={onFileChange}
                      fileList={[]}
                    >
                      {curImg ? (
                        <img src={curImg} alt="avatar" className="full-width" />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                    {curImg?.companyLogo}
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={12}>
                  <Form.Item
                    name="companyName"
                    label="Company Name"
                    validateFirst
                    rules={[
                      { ...required, message: 'Please Enter Company Name' },
                      charLimit,
                      name,
                      noSpacesOnly,
                    ]}
                  >
                    <Input placeholder="Enter Company Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12} xl={12}>
                  <Form.Item name="primaryColor" label="Primary Color">
                    <ColorPicker showText />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  xxl={24}
                  className="instructions"
                >
                  <Form.Item
                    name="instructions"
                    label="Instructions"
                    rules={[{ required: true }, instruction]}
                  >
                    <QuillEditor hasError={hasError} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Card>
        </Form>
      </LoaderComponent>
      {!isFree && (
        <RouterPrompt
          isPrompt={isPrompt}
          handleOK={handleOk}
          handleCancel={handleClose}
        />
      )}
    </>
  );
}

export default OnBoarding;
